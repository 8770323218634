import React from 'react'

const Thankyou = ({ setThankYou, onClick, msg }) => {
    return (
        <section className="section_bg login_section section-py">
            <div className="container">
                <div className="row no-gutters">
                    <div className="col-lg-12 col-md-12">
                        <div className="white_rounded_box">

                            {/* <!-- Tab panes --> */}
                            <div className="tab-content">

                                <div className="tab-pane active" id="tab-register" role="tabpanel">


                                    <div className="tankyou_div">

                                        <div className="check_mark">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="49.078" height="49.354" viewBox="0 0 49.078 49.354">
                                                <g id="Group_2657" data-name="Group 2657" transform="translate(0)">
                                                    <path id="Path_3040" data-name="Path 3040" d="M52.791,24.176l-1.036-.709A3.209,3.209,0,0,1,50.5,19.924l.327-1.2a3.26,3.26,0,0,0-2.617-4.089l-1.254-.218A3.182,3.182,0,0,1,44.287,11.8l-.218-1.254A3.228,3.228,0,0,0,39.98,7.986l-1.2.382a3.267,3.267,0,0,1-3.543-1.2l-.763-1.09a3.215,3.215,0,0,0-4.8-.491l-.927.872a3.207,3.207,0,0,1-3.761.436L23.9,6.3a3.278,3.278,0,0,0-4.579,1.635l-.491,1.2a3.13,3.13,0,0,1-3.162,2.017l-1.254-.055a3.191,3.191,0,0,0-3.38,3.434l.109,1.254A3.275,3.275,0,0,1,9.18,19l-1.145.491a3.257,3.257,0,0,0-1.581,4.579l.654,1.09a3.207,3.207,0,0,1-.382,3.761l-.818.927a3.181,3.181,0,0,0,.6,4.8l1.036.709A3.209,3.209,0,0,1,8.8,38.9l-.491,1.254a3.26,3.26,0,0,0,2.617,4.089l1.254.218a3.182,3.182,0,0,1,2.671,2.617l.218,1.254a3.228,3.228,0,0,0,4.089,2.562l1.2-.382a3.267,3.267,0,0,1,3.543,1.2l.763,1.036a3.215,3.215,0,0,0,4.8.491l.927-.872a3.207,3.207,0,0,1,3.761-.436l1.09.6a3.278,3.278,0,0,0,4.579-1.635l.491-1.2a3.129,3.129,0,0,1,3.162-2.017l1.254.055a3.191,3.191,0,0,0,3.38-3.434l-.055-1.254a3.275,3.275,0,0,1,1.963-3.216l1.145-.491a3.257,3.257,0,0,0,1.581-4.579l-.654-1.09a3.207,3.207,0,0,1,.382-3.761l.818-.927A3.172,3.172,0,0,0,52.791,24.176Z" transform="translate(-5.081 -4.733)" fill="#33a470" />
                                                    <g id="Group_2463" data-name="Group 2463" transform="translate(14.719 18.535)">
                                                        <line id="Line_241" data-name="Line 241" x2="6.375" y2="6.375" transform="translate(0 6.375)" fill="none" stroke="#fff" stroke-linecap="round" strokeWidth="4" />
                                                        <line id="Line_242" data-name="Line 242" y1="12.75" x2="13.388" transform="translate(6.375)" fill="none" stroke="#fff" stroke-linecap="round" strokeWidth="4" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>

                                        <div className="thankyou_content">
                                            <p>Thank you.</p>
                                            <p>We will connect with you soon.</p>
                                            {msg ? <p>{msg}</p> : null}
                                        </div>

                                        <div className="btn_wrap">
                                            <button className="btn btn-primary" onClick={() => onClick ? onClick() : setThankYou(false)}>Continue</button>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Thankyou
