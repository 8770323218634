import React from 'react'

const LinkButton = (props) => {

    const { title, onBtnClick, resend, loading } = props

    return (
        <div className={`resend_otp ${loading ? 'link-disabled' : ''}`}>
            <a href="javascript:;" onClick={(e) => onBtnClick && !loading ? onBtnClick(e): {}} className="verifyLink d-flex-aic">
                {title} {resend ? <img className='ml-1' src="/assets/images/icons/icon_rotate.svg" alt="" /> : null}
                {loading ? <span className="loading"></span> : null}
            </a>
        </div>
    )
}

export default LinkButton
 